#footer {
  border-bottom: 70px solid #ee7100;
}

#footer .contactform-container {
  background: #f3f3f3;
}

#footer .contactform-container .contactform {
  padding-top: 35px;
  padding-bottom: 25px;
}

#footer .footer-content {
  padding: 25px 0;
}

@media (min-width: 768px) {
  #footer .footer-content {
    padding-top: 100px;
  }
}

@media (max-width: 1023px) {
  #footer .footer-content > div:nth-of-type(3) {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  #footer .footer-content .contact-info {
    padding-top: 60px;
  }
}

#footer .footer-content .contact-info img {
  max-width: 190px;
  margin-bottom: 20px;
}

#footer .footer-content .contact-info .contact-ul li {
  padding: 0 0 5px 0;
}

#footer .footer-content .contact-info .contact-ul li span {
  width: 20px;
  display: inline-block;
  margin: 0 10px 0 0;
}

#footer .footer-content .contact-info .contact-ul li span img {
  max-height: 16px;
  display: block;
  margin: 0;
  float: right;
}

#footer .footer-content .contact-info .contact-ul li a {
  padding-left: 5px;
  color: #868686;
}

#footer .footer-content .footer-imgs .main {
  height: 120px;
}

@media (max-width: 1023px) {
  #footer .footer-content .footer-imgs .main {
    height: auto;
    margin-bottom: 15px;
  }
}

#footer .footer-content .footer-imgs ul.imgs {
  margin: 0;
}

#footer .footer-content .footer-imgs ul.imgs li {
  float: left;
  max-width: 30%;
  margin-right: 3%;
}
