@import "inc/inc";

#footer {
    border-bottom: 70px solid $brand-primary;


    .contactform-container {
        background: #f3f3f3;
        .contactform {
            padding-top: 35px;
            padding-bottom: 25px;
        }
    }

    .footer-content {
        padding: 25px 0;
        @include media-breakpoint-up('md') {
            padding-top: 100px;
        }

        @include media-breakpoint-down('md') {
            > div:nth-of-type(3) {
                margin-top: 30px;
            }
        }

        .contact-info {
            @include media-breakpoint-up('md') {
                padding-top: 60px;
            }
            img {
                max-width: 190px;
                margin-bottom: 20px;
            }

            .contact-ul {
                li {
                    padding: 0 0 5px 0;
                    span {
                        width: 20px;
                        display: inline-block;
                        margin: 0 10px 0 0;
                        img {
                            max-height: 16px;
                            display: block;
                            margin: 0;
                            float: right;
                        }
                    }
                    a {
                        padding-left: 5px;
                        color: $brand-default
                    }
                }
            }
        }

        .footer-imgs {

            .main {
                height: 120px;
                @include media-breakpoint-down('md') {
                    height: auto;
                    margin-bottom: 15px;
                }
            }

            ul.imgs {
                margin: 0;

                li {
                    float: left;
                    max-width: 30%;
                    margin-right: 3%;
                }
            }
        }
    }
}

